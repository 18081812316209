<template>
  <div class="video">
    <div class="video-left">
      <div class="video-left-head">课程详情</div>
      <div class="video-container" v-loading="loading">
        <div class="video-container-wrap">
          <video ref="videoElement" controls width="760" @play="onPlay" @pause="onPause" :poster="cover"
            style="cursor: pointer"></video>
          <div class="gif-overlay" v-if="isGif && !isMobile">
            <img src="../../images/newHome/video.gif" alt="Play Icon" class="icon" />
          </div>
        </div>
        <div class="video-container-title">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="video-right">
      <LiveBroadcast :videList="videList" title="iLaw直播" v-if="isMobile"></LiveBroadcast>
      <template v-else>
        <div class="live-broadcast">
          <div class="live-broadcast-head">
            <div class="live-broadcast-head-left">iLaw直播</div>
            <div class="live-broadcast-head-right" style="cursor: pointer" @click="articleToView(2)">
              <span>查看更多</span>
              <img src="../../images/newHome/resourceCentre/arrow.png" alt="" class="arrow" />
            </div>
          </div>
          <div class="live-broadcast-card">
            <div class="live-broadcast-card-item" v-for="(item, index) of videList" :key="index"
              @click="openLink(item.link)">
              <div class="live-broadcast-card-item-left">
                <div class="live-broadcast" v-html="supplement(item.cover, 'bg')"></div>
              </div>
              <div class="live-broadcast-card-item-right" style="cursor: pointer">
                <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
                  <div class="title">{{ item.title }}</div>
                </el-tooltip>
                <div class="time">
                  {{
                    item.articleUpdateTime && item.articleUpdateTime.split(" ")[0]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="seek-advice-from">
        <img src="../../images/newHome/resourceCentre/bj.png" alt="" class="bj" />
        <div class="qr-code">
          <img src="../../images/newHome/resourceCentre/qr-code.png" alt="" class="code" />
          <div class="title">扫码联系圈圈，了解更多</div>
        </div>
      </div>
      <MobileFooter v-if="isMobile"></MobileFooter>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./index.scss";
@import "@/pages/css/mobile-base.scss";
@media (max-width: $MobileWidth) {
  @import "./mobile-selectedVideo.scss"
}

@media (min-width: $MobileWidth) {
  .live-broadcast-card-item-left {
    .live-broadcast {
      width: 120px;
      height: 120px;
    }
  }

  ::v-deep iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>

<script>
import LiveBroadcast from "@/components/live-broadcast/index";
import MobileFooter from "@/components/mobile-footer/index";
import api from "../../api/request";
import Hls from "hls.js";
import { mapState } from 'vuex'
export default {
  name: "selectedVideo",
  components: {
    LiveBroadcast,
    MobileFooter
  },
  data() {
    return {
      videList: [],
      player: null,
      isGif: true,
      cover: "",
      title: "“碳”路前行：脱离内卷，拥抱碳中和",
      videoUrl: "",
      loading: false,
      hls: null,
    };
  },
  computed: {
    ...mapState(['isMobile']),
  },
  watch: {
    isMobile(newVal) {
      console.log(newVal);
    }
  },
  methods: {
    onPlay() {
      this.isGif = false;
    },
    onPause() {
      this.isGif = true;
    },
    supplement(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:100%;height:100%;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    },
    async getSupplement() {
      const params = {
        pageNum: 1,
        pageSize: 5,
      };
      const { code, msg, data } = await api.getSupplement(params);
      if (code === 0) {
        this.videList = data.list || [];
      } else {
        this.$message.error(msg);
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    async getVideoUrl() {
      const params = { uuid: this.$route.query.uuid };
      const { code, msg, data } = await api.getVideoUrl(params);
      if (code === 0) {
        return data;
      } else {
        this.$message.error(msg);
      }
    },
    articleToView(type) {
      const url = window.location.origin;
      window.open(`${url}/article?type=${type}`);
    },
  },

  async mounted() {
    if (this.$route.query.type === "1") {
      this.cover = require("../../images/newHome/resourceCentre/banner1.jpg");
      this.title = "“碳”路前行：脱离内卷，拥抱碳中和";
    } else if (this.$route.query.type === "2") {
      this.cover = require("../../images/newHome/resourceCentre/banner2.jpg");
      this.title = "企业如何合规管理信息化";
    } else {
      this.cover = require("../../images/newHome/resourceCentre/banner3.jpg");
      this.title = "数据资产入表操作指南";
    }
    const video = this.$refs.videoElement;
    const url = await this.getVideoUrl();
    this.getSupplement();
    if (Hls.isSupported()) {
      this.hls = new Hls();
      // 绑定 hls 实例到 video 元素
      this.hls.attachMedia(video);
      // 加载 HLS 视频流
      this.hls.loadSource(url);
      // 组件销毁时销毁 hls 实例
      this.$once("hook:beforeDestroy", () => {
        if (this.hls && this.hls.destroy) {
          this.hls.destroy();
        }
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      // HLS 支持的浏览器，直接使用 video.src
      this.$refs.videoElement.src = url;
    }
  },
};
</script>
